import React from "react"
import PropTypes from "prop-types"
class Upvote extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id,
      liked: !!props.id,
      num: props.num,
    };
  }

  onClick  = () => {
    if (this.state.liked) {
      fetch(`/upvotes/${this.state.id}`, {
        method: 'DELETE',
        headers: {
          "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
          "Content-Type": "application/json",
        }
      }).then(respJson => respJson.json())
        .then(resp => {
          if (resp.status === 'ok') {
            this.setState({ liked: false, num: resp.total_count });
          }
        })
    } else {
      fetch('/upvotes', {
        method: 'POST',
        headers: {
          "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
                upvote: {
                  upvotable_id: this.props.upvotableId,
                  upvotable_type: this.props.upvotableType,
                }
              })
      }).then(respJson => respJson.json())
        .then(resp => {
          if (resp.status === 'ok') {
            this.setState({ liked: true, num: resp.total_count, id: resp.id });
          }
        })
    }
  }

  render () {
    return (
      <div className={ `upvote kt-badge ${ !this.state.liked && 'kt-badge--outline' || ''} kt-badge--xl d-block` } onClick={ this.onClick }>
        <div className="num-wrapper text-center">
          <i className="la la-angle-up" />
          <div className="num" >{ this.state.num }</div>
        </div>
      </div>
    );
  }
}

Upvote.propTypes = {
  num: PropTypes.number,
  liked: PropTypes.bool,
  upvotableId: PropTypes.number,
  upvotableType: PropTypes.string,
};
export default Upvote
