import React from "react"
import PropTypes from "prop-types"

class FilterButtonDropdown extends React.Component {
  toggle = (event) => {
    const value = event.currentTarget.textContent;
    let newActiveItems = this.props.activeItems;

    if (newActiveItems.indexOf(value) == -1) {
      newActiveItems.push(value);
    } else {
      newActiveItems = newActiveItems.filter(item => item !== value);
    }

    const newActivityState = !!newActiveItems.length;
    const inactiveItems = this.props.items.filter(item => newActiveItems.indexOf(item) > -1);

    this.props.onClick && this.props.onClick(newActiveItems, inactiveItems);

    event.preventDefault();
  }

  render () {
    return (
      <span className="dropdown kt-margin-r-5">
        <button className={`btn ${ this.props.activeItems.length > 0 ? 'btn-brand-secondary' : 'btn-brand'} btn-portal btn-elevate dropdown-toggle font-weight-bold kt-margin-b-5`} data-toggle="dropdown">{ this.props.name }</button>
        <div className="dropdown-menu">
          { this.props.items.map((item, idx) => {
            const itemActive = this.props.activeItems.indexOf(item) > -1;

            return (
              <a key={ idx } className={`dropdown-item ${ itemActive && 'selected' || '' }`} href="#" onClick={ this.toggle } >
                { item }
                { itemActive && <i className="las la-check kt-margin-l-5"></i> }
              </a>
            )
          }) }
        </div>
      </span>
    )
  }
}

FilterButtonDropdown.propTypes = {
  active: PropTypes.bool,
  activeItems: PropTypes.array,
  name: PropTypes.string,
  items: PropTypes.array,
  onClick: PropTypes.func,
};
export default FilterButtonDropdown
