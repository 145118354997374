import React from "react"
import PropTypes from "prop-types"

class SearchInput extends React.Component {
  render () {
    return (
      <React.Fragment>
        <label> { this.props.label || 'Search:' } </label>
        <div className="kt-input-icon kt-input-icon--left">
          <input type="text" className="form-control" placeholder="Search..." name="search" onChange={ this.props.onChange } value={ this.props.value } autoComplete="off"/>
          <span className="kt-input-icon__icon kt-input-icon__icon--left">
            <span>
              <i className="la la-search" />
            </span>
          </span>
        </div>
      </React.Fragment>
    );
  }
}

SearchInput.propTypes = {
  onChange: PropTypes.func
};
export default SearchInput
