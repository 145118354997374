import React from "react"
import PropTypes from "prop-types"
import CardList from "./CardList";
import SearchInput from "./SearchInput";
import CardSelect from "./CardSelect";
import CardTags from "./CardTags";
import ModalButton from "./ModalButton";
import InternalMessageShare from './InternalMessageShare';
import FilterButton from './FilterButton';
import FilterButtonDropdown from './FilterButtonDropdown';


class ExternalCourses extends React.Component {
  constructor(props) {
    super(props);
    this.cardList = React.createRef();

    // this.handleClickTag = this.handleClickTag.bind(this);

    this.data = this.props.data;
    this.data = this.data.map(dataItem => {
      if (!dataItem['imageUrl']) {
        dataItem['iconName'] = 'la la-brain'
      }
      dataItem['handleClickTag'] = this.handleClickTag;

      return dataItem;
    })

    this.originalLocation = window.location.href.split('?')[0];
    this.location = window.location.href.split('?')[0];

    this.singleTagSelection = props.singleTagSelection || false;

    this.state = {
      series: this.parseFilterOptions().series ? this.parseFilterOptions().series : [],
      tags: this.parseFilterOptions().tags ? this.parseFilterOptions().tags : [],
      search: this.parseFilterOptions().search ? this.parseFilterOptions().search : '',
      sort: 'createdAt DESC',
      hideFilters: props.hideFilters,
    };
  }

  componentDidUpdate() {
    const { series, tags, search } = this.state;
    const params = new URLSearchParams(Object.fromEntries(Object.entries({ series, tags, search }).filter(([key, val]) => val))).toString();
    window.history.replaceState(this.state, '', `${ this.originalLocation }?${ params }`);
    this.location = window.location;
  }

  parseFilterOptions() {
    let params = new URLSearchParams(document.location.search)
    let options = {};

    if (params.get("tags")) { options.tags = params.get("tags").split(',').map(val => val.trim()) };
    if (params.get("series")) { options.series = params.get("series").split(',').map(val => val.trim()) };
    if (params.get("search")) { options.search = params.get("search") };

    return options;
  }

  filterItem (dataItem) {
    return (this.state.tags.length === 0 || this.state.tags.filter(x => (dataItem.tags || []).includes(x)).length > 0) &&
            (this.state.series.length === 0 || this.state.series.filter(x => (dataItem.series || []).includes(x)).length > 0) &&
            (this.state.search === '' ||
              dataItem.title && dataItem.title.toLowerCase().includes(this.state.search) ||
              dataItem.author && dataItem.author.toLowerCase().includes(this.state.search) ||
              dataItem.description && dataItem.description.toLowerCase().includes(this.state.search));
  }

  filter (data) {
    return data.filter(dataItem => this.filterItem(dataItem));
  }

  sort (data) {
    if (this.state.sort === '') {
      return data;
    }

    const [sortBy, sortOrder] = this.state.sort.split(' ');

    // console.log('FILES#sort', sortBy, sortAsc);

    // if (sortAsc === 'ASC') {
    //   return data.sort((e1, e2) => e1[sortBy] - e2[sortBy] )
    // } else {
    //   return data.sort((e1, e2) => e2[sortBy] - e1[sortBy] )
    // }
    return data.sort(this.sortComparator(sortOrder, sortBy))
  }

  sortComparator (order, sortBy) {
    return function (el1, el2) {
      el1 = el1[sortBy];
      el2 = el2[sortBy];
      if (typeof el1 === 'string' || el1 instanceof String) {
        return (order === 'ASC') && el1.localeCompare(el2) || el2.localeCompare(el1);
      }

      return (order === 'ASC') && (el1 - el2) || (el2 - el1)
    }
  }

  toggleClickFilterButton = (newItem, toAdd = true, key = 'tags') => {
    let data = this.state[key];
    if (toAdd) {
      if (this.singleTagSelection) {
        data = [newItem];
      } else {
        data.push(newItem);
      }
    } else {
      data = data.filter(item => item !== newItem);
    }

    let nullifyKey = key == 'tags' && 'series' || key == 'series' && 'tags'

    this.setState({ [key]: data, [nullifyKey]: [] }, () => { $('.bootstrap-select').selectpicker('refresh'); });
    this.nullifyPagegination();
  }

  toggleClickFilterButtonDropdown = (values, inactiveValues, key = 'tags') => {
    let data = this.state[key];
    data = data.filter(item => inactiveValues.indexOf(item) > -1);
    data = data.concat(values);

    // Remove duplicates
    data = [...new Set(data)];

    let nullifyKey = key == 'tags' && 'series' || key == 'series' && 'tags'

    this.setState({ [key]: data, [nullifyKey]: [] }, () => { $('.bootstrap-select').selectpicker('refresh'); });
    this.nullifyPagegination();
  }

  handleClickTag = (event) => {
    // event.preventDefault();
    this.setState({ tags: [event.currentTarget.textContent], series: [] }, () => { $('.bootstrap-select').selectpicker('refresh'); });
    this.nullifyPagegination();
  }

  handleClickSeries = (event) => {
    this.setState({ series: [event.currentTarget.textContent], tags: [] }, () => { $('.bootstrap-select').selectpicker('refresh'); });
    this.nullifyPagegination();
  }

  handleChangeTags = (event) => {
    this.setState({ tags: [...event.target.selectedOptions].map(o => o.value), series: [] });
    this.nullifyPagegination();
  }

  handleChangeSeries = (event) => {
    this.setState({ series: [...event.target.selectedOptions].map(o => o.value), tags: [] });
    this.nullifyPagegination();
  }

  handleChangeSearch(event) {
    this.setState({ search: event.target.value.toLowerCase() });
    this.nullifyPagegination();
  }

  handleChangeSort(event) {
    this.setState({ sort: event.target.value });
    this.nullifyPagegination();
  }

  nullifyPagegination () {
    this.cardList.current.setState({ activePage: 1 });
  }

  renderDataItemVideoModal (videoUrl, id) {
    return (
      <iframe src={ videoUrl } width="100%" height="480" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
    )
  }
      // <div id={ id } className="modal fade" area-hidden="true" role="dialog" style={{ display: 'none', }} tabIndex="-1">
      //   <div className="modal-xl modal-dialog modal-dialog-centered" role="document">
      //     <div className="modal-content bg-transparent border-none">
      //       <div className="modal-header border-none">
      //         <button className="close text-white" aria-label="Close" data-dismiss="modal" type="button" />
      //       </div>
      //       <div className="modal-body kt-padding-0">
      //       </div>
      //     </div>
      //   </div>
      // </div>
      //   h5.modal-title.text-danger = t('subscriptions.new.modal.title')
      //   button.close aria-label="Close" data-dismiss="modal" type="button"
      // = simple_form_for([:admin, Subscription.new]) do |f|
      //   .modal-body

  renderAuthorLink (data, key=0) {
    const { name, url } = data;

    return (
      <a className="kt-link kt-margin-r-5" href={ url } target="_blank" key={ key }>
        <div className="kt-font-brand">{ name }{ data.authors && (key != (data.authors.length - 1)) ? ',' : '' }</div>
      </a>
    )
  }

  renderAuthor (data) {
    if (data.author) {
      if (data.authorLink) {
        return this.renderAuthorLink(data.author, data.authorLink)
      } else {
        return (<div className="kt-font-brand">{data.author}</div>)
      }
    } else if (data.authors) {
      return (
        <div>
          {
            data.authors.map((adata, idx) => this.renderAuthorLink(adata, idx))
          }
        </div>
      )
    }
  }

  renderDataItem(data, idx) {
    return (
      <React.Fragment>
        <div className="kt-portlet__body kt-portlet__body--fit-bottom kt-ribbon kt-ribbon--brand kt-ribbon--ver kt-ribbon--bottom kt-ribbon--shadow kt-ribbon--round">
          <div className="kt-margin-b-10">
            {
              data.imageUrl &&
                <div className="card-image text-center d-flex">
                  <img className="align-self-center" src={ data.imageUrl } />
                </div> ||
                  data.iconName &&
                  <div className="text-center">
                    <i className={`${data.iconName} card-image`} />
                  </div>
            }
          </div>
          { data.title && (
              data.link &&
                <a className="kt-font-dark kt-margin-b-20" href={data.link}>
                  <h3 className="card-title d-inline">{ data.title }</h3>
                </a> ||
                <React.Fragment>
                  <h3 className="kt-font-dark kt-margin-b-20 card-title d-inline">{ data.title }</h3>
                </React.Fragment>) }
          { this.renderAuthor(data) }
          { data.description && <div className="kt-font-dark card-description">{data.description}</div> }
          <div className="kt-margin-t-10">
            { data.tags && <CardTags tags={ data.tags } onClick={ this.handleClickTag } noWrapper={ true } /> }
            { data.series && <CardTags tags={ data.series } onClick={ this.handleClickSeries } noWrapper={ true } /> }
          </div>
        </div>
        <div className="kt-portlet__foot kt-portlet__foot--no-border">
          { data.link && <a className="btn btn-brand btn-bold btn-portal btn-elevate" href={data.link}>{data.linkTitle || 'Read more'}</a> }
          { data.accessUrl && !data.link && <a className="btn btn-orange2 btn-bold btn-portal btn-elevate" href={data.accessUrl}>{data.accessUrlTitle || 'Upgrade'}</a> }
        </div>
      </React.Fragment>
    )
  }
          // { data.videoUrl &&
          //   <ModalButton
          //     buttonClass="btn btn-brand btn-bold btn-portal btn-elevate"
          //     buttonLabel={ data.videoUrlTitle || 'Watch now' }
          //     modalContent={ this.renderDataItemVideoModal(data.videoUrl, `video${idx}`) }
          //   />
          // }
              // <a className="btn btn-brand btn-bold btn-portal btn-elevate" href="" data-toggle='modal' data-target={ `video${idx}` }>{data.videoUrlTitle || 'Watch now'}</a>
              // { this.renderDataItemVideoModal(data.videoUrl, `video${idx}`) }

  renderFilterButtons() {
    if (!this.props.filterButtonsTags || !this.props.filterButtonsSeries) return

    return (
      <div className="kt-margin-b-20">
        <div className="kt-margin-b-20">
          <SearchInput onChange={ this.handleChangeSearch.bind(this) } value={ this.state.search } label=" " />
        </div>

        {
          (this.props.filterButtonsTags || []).map((label, idx) => <FilterButton key={ idx } name={ label } active={ this.state.tags.indexOf(label) > -1 } onClick={ (value, active) => this.toggleClickFilterButton(value, active) } />)
        }
        {
          Object.entries(this.props.filterDropdowns || {}).map(([label, items], idx) => <FilterButtonDropdown key={ idx } name={ label } items={ items } activeItems={ this.state.tags.filter(item => items.indexOf(item) > -1) } onClick={ (values, inactiveValues) => this.toggleClickFilterButtonDropdown(values, inactiveValues) } />)
        }
        {
          (this.props.filterButtonsSeries || []).map((label, idx) => <FilterButton key={ idx } name={ label } active={ this.state.series.indexOf(label) > -1 } onClick={ (value, active) => this.toggleClickFilterButton(value, active, 'series') } />)
        }
        {/*<button className="btn btn-brand btn-bold btn-portal btn-elevate kt-margin-l-5 kt-margin-b-20" onClick={ () => this.setState({ hideFilters: !this.state.hideFilters }) }>
          { this.state.hideFilters && 'Other' || 'Hide filterss' }
        </button>*/}
      </div>
    )
  }

  render () {
    return (
      <React.Fragment>
        {
          this.renderFilterButtons()
        }
        {
          <div className={ this.state.hideFilters && 'd-none' || '' }>
            {/*<a className="btn btn-brand btn-bold btn-portal btn-elevate" href="#" onClick={ this.handleClickTag }>Finance</a>*/}

            <div className="row kt-margin-b-20-desktop">
              <div className="col-md-3 kt-margin-b-20-tablet-and-mobile">
                <SearchInput onChange={ this.handleChangeSearch.bind(this) } value={ this.state.search } />
              </div>

              <div className="col-md-3 kt-margin-b-20-tablet-and-mobile">
                <CardSelect
                  values={ this.state.tags }
                  onChange={ this.handleChangeTags }
                  options={ this.props.tags }
                  title={ this.props.tagsTitle } />
              </div>

              <div className="col-md-3 kt-margin-b-20-tablet-and-mobile">
                <CardSelect
                  values={ this.state.series }
                  onChange={ this.handleChangeSeries }
                  options={ this.props.series }
                  title={ this.props.seriesTitle } />
              </div>

              <div className="col-md-3">
                <div className="kt-form__control">
                  <label>
                    Sort by:
                  </label>
                  <select
                    className="form-control bootstrap-select"
                    value={ this.state.sort }
                    onChange={ this.handleChangeSort.bind(this) }>
                    <option value="title ASC">Name (alphabetical)</option>
                    <option value="title DESC">Name (alphabetical reverse)</option>
                    <option value="updatedAt DESC">Last updated (newest first)</option>
                    <option value="updatedAt ASC">Last updated (oldest first)</option>
                    <option value="createdAt DESC">Created (newest first)</option>
                    <option value="createdAt ASC">Created (oldest first)</option>
                  </select>
                </div>
              </div>
            </div>
            {/*<InternalMessageShare />*/}
          </div>
        }

        <CardList
          ref={ this.cardList }
          dataItems={ this.sort(this.filter(this.data)).map((dataItem, idx) => { return { body: this.renderDataItem(dataItem, idx), cardProps: dataItem } }) }
          perPage={ 12 }
          cardWrapperClass={ 'col-lg-3 col-md-4' }
        />
      </React.Fragment>
    );
  }
}

ExternalCourses.propTypes = {
  data: PropTypes.array,
  cardWrapperClass: PropTypes.string,
  hideFilters: PropTypes.bool,
  singleTagSelection: PropTypes.bool,
};
export default ExternalCourses
