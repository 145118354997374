import React from "react"
import PropTypes from "prop-types"
import CardList from "./CardList";
import CardSelect from "./CardSelect";

class FeaturedInvestors extends React.Component {
  constructor(props) {
    super(props);
    this.cardList = React.createRef();
  }

  render () {
    return (
      <React.Fragment>
        <CardList
          ref={ this.cardList }
          data={ this.props.data }
          perPage={ 12 }
          cardWrapperClass={ 'col-lg-3 col-md-4' }
        />
      </React.Fragment>
    );
  }
}

FeaturedInvestors.propTypes = {
  data: PropTypes.array,
  cardWrapperClass: PropTypes.string
};
export default FeaturedInvestors
