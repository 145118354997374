import React from "react"
import PropTypes from "prop-types"
import CardList from "./CardList";
import CardSelect from "./CardSelect";

class Files extends React.Component {
  constructor(props) {
    super(props);
    this.cardList = React.createRef();

    this.state = {
      categories: [],
      search: '',
      sort: 'published_at DESC'
    };
  }

  filterItem (fileData) {
    return (this.state.categories.length === 0 || this.state.categories.includes(fileData.category)) &&
            (this.state.search === '' ||
              fileData.title && fileData.title.toLowerCase().includes(this.state.search) ||
              fileData.description && fileData.description.toLowerCase().includes(this.state.search));
  }

  filter (data) {
    // console.log('filter Files');
    return data.filter(fileData => this.filterItem(fileData));
  }

  sort (data) {
    if (this.state.sort === '') {
      return data;
    }

    const [sortBy, sortAsc] = this.state.sort.split(' ');

    // console.log('FILES#sort', sortBy, sortAsc);

    if (sortAsc === 'ASC') {
      return data.sort((e1, e2) => e1[sortBy] - e2[sortBy] )
    } else {
      return data.sort((e1, e2) => e2[sortBy] - e1[sortBy] )
    }
  }

  handleClickSeries = (event) => {
    this.setState({ series: [event.currentTarget.textContent] }, () => { $('.bootstrap-select').selectpicker('refresh'); });
    this.nullifyPagegination();
  }

  handleChangeCategories(event) {
    this.setState({ categories: [...event.target.selectedOptions].map(o => o.value) });
    this.nullifyPagegination();
  }

  handleChangeSearch(event) {
    this.setState({ search: event.target.value.toLowerCase() });
    this.nullifyPagegination();
  }

  handleChangeSort(event) {
    this.setState({ sort: event.target.value });
    this.nullifyPagegination();
  }

  nullifyPagegination () {
    this.cardList.current.setState({ activePage: 1 });
  }

  renderDataItem(data, idx) {
    return (
      <React.Fragment>
        <div className="kt-portlet__body kt-portlet__body--fit-bottom">
          { data.iconName &&
            <div className="text-center">
              <i className={`${data.iconName} card-image`} />
            </div> }
          { data.imageUrl &&
            <div className="card-image text-center d-flex">
              <img className="align-self-center" src={ data.imageUrl } />
            </div> }
          { data.title && (
              data.link &&
                <a className="kt-font-dark kt-margin-b-20" href={data.link}>
                  <h3 className="card-title d-inline">{ data.title }</h3>
                </a> ||
                <React.Fragment>
                  <h3 className="kt-font-dark kt-margin-b-20 card-title d-inline">{ data.title }</h3>
                </React.Fragment>) }
          { this.renderAuthor(data) }
          { data.description && <div className="kt-font-dark card-description">{data.description}</div> }
          <div className="kt-margin-t-10">
            { data.series && <CardTags tags={ data.series } onClick={ this.handleClickSeries } noWrapper={ true } /> }
          </div>
        </div>
        <div className="kt-portlet__foot kt-portlet__foot--no-border">
          { data.link && <a className="btn btn-brand btn-bold btn-portal btn-elevate" href={data.link}>{data.linkTitle || 'Read more'}</a> }
          { data.videoUrl &&
            <ModalButton
              buttonClass="btn btn-brand btn-bold btn-portal btn-elevate"
              buttonLabel={ data.videoUrlTitle || 'Watch now' }
              modalContent={ this.renderDataItemVideoModal(data.videoUrl, `video${idx}`) }
            />
          }
        </div>
      </React.Fragment>
    )
  }

  render () {
    return (
      <React.Fragment>
        <div className="row kt-margin-b-20">
          <div className="col-md-4 kt-margin-b-20-tablet-and-mobile">
            <label> Name: </label>
            <div className="kt-input-icon kt-input-icon--left">
              <input type="text" className="form-control" placeholder="Search..." name="search" onChange={this.handleChangeSearch.bind(this)} />
              <span className="kt-input-icon__icon kt-input-icon__icon--left">
                <span>
                  <i className="la la-search" />
                </span>
              </span>
            </div>
          </div>

          <div className="col-md-4 kt-margin-b-20-tablet-and-mobile">
            <CardSelect
              values={ this.state.categories }
              onChange={ this.handleChangeCategories.bind(this) }
              options={ this.props.categories } />
          </div>

          <div className="col-md-4">
            <div className="kt-form__control">
              <label>
                Sort by:
              </label>
              <select
                className="form-control bootstrap-select"
                value={ this.state.sort }
                onChange={ this.handleChangeSort.bind(this) }>
                <option value="published_at DESC">Publish date (newest first)</option>
                <option value="published_at ASC">Publish date (oldest first)</option>
                <option value="updated_at DESC">Updated (newest first)</option>
                <option value="updated_at ASC">Updated (oldest first)</option>
              </select>
            </div>
          </div>
        </div>

        <CardList
          ref={ this.cardList }
          dataItems={ this.sort(this.filter(this.props.data)).map((dataItem, idx) => { return { body: this.renderDataItem(dataItem, idx), cardProps: dataItem } }) }
          perPage={ 12 }
          cardWrapperClass={ 'col-lg-3 col-md-4' }
        />
      </React.Fragment>
    );
  }
}

Files.propTypes = {
  data: PropTypes.array,
  cardWrapperClass: PropTypes.string
};
export default Files
