import React from "react"
import PropTypes from "prop-types"
import CardList from "./CardList";
import SearchInput from "./SearchInput";
import CardSelect from "./CardSelect";

class ServiceProviders extends React.Component {
  constructor(props) {
    super(props);
    this.cardList = React.createRef();

    this.handleClickTag = this.handleClickTag.bind(this);

    this.data = this.props.data;
    this.data = this.data.map(dataItem => {
      if (!dataItem['imageUrl']) {
        dataItem['iconName'] = 'la la-building'
      }
      dataItem['handleClickTag'] = this.handleClickTag;

      return dataItem;
    })

    this.state = {
      tags: [],
      search: '',
      location: '',
    };
  }

  filterItem (dataItem) {
    return (this.state.tags.length === 0 || this.state.tags.filter(x => (dataItem.tags || []).includes(x)).length > 0) &&
            (this.state.search === '' ||
              dataItem.title && dataItem.title.toLowerCase().includes(this.state.search) ||
              dataItem.author && dataItem.author.toLowerCase().includes(this.state.search)) &&
            (this.state.location === '' ||
              dataItem.description && dataItem.description.toLowerCase().includes(this.state.location));
  }

  filter (data) {
    return data.filter(dataItem => this.filterItem(dataItem));
  }

  handleClickTag(event) {
    this.setState({ tags: [event.currentTarget.textContent] }, () => { $('.bootstrap-select').selectpicker('refresh'); });
    this.nullifyPagegination();
  }

  handleChangeTags(event) {
    this.setState({ tags: [...event.target.selectedOptions].map(o => o.value) });
    this.nullifyPagegination();
  }

  handleChangeSearch(event) {
    this.setState({ search: event.target.value.toLowerCase() });
    this.nullifyPagegination();
  }

  handleChangeLocation(event) {
    this.setState({ location: event.target.value.toLowerCase() });
    this.nullifyPagegination();
  }

  handleChangeSort(event) {
    this.setState({ sort: event.target.value });
    this.nullifyPagegination();
  }

  nullifyPagegination () {
    this.cardList.current.setState({ activePage: 1 });
  }

  render () {
    return (
      <React.Fragment>
        {
          !this.props.hideFilters &&
            <div className="row kt-margin-b-20">
              <div className="col-md-4 kt-margin-b-20-tablet-and-mobile">
                <SearchInput onChange={ this.handleChangeSearch.bind(this) } />
              </div>

              <div className="col-md-4 kt-margin-b-20-tablet-and-mobile">
                <SearchInput onChange={ this.handleChangeLocation.bind(this) } label="Location search:" />
              </div>

              <div className="col-md-4 kt-margin-b-20-tablet-and-mobile">
                <CardSelect
                  values={ this.state.tags }
                  onChange={ this.handleChangeTags.bind(this) }
                  options={ this.props.tags }
                  title={ this.props.tagsTitle } />
              </div>
            </div>
        }

        <CardList
          ref={ this.cardList }
          data={ this.filter(this.data) }
          perPage={ 12 }
          cardWrapperClass={ 'col-lg-3 col-md-4' }
        />
      </React.Fragment>
    );
  }
}

ServiceProviders.propTypes = {
  data: PropTypes.array,
  cardWrapperClass: PropTypes.string,
  hideFilters: PropTypes.bool,
};
export default ServiceProviders
