import React from "react"
import PropTypes from "prop-types"

class FilterButton extends React.Component {
  toggle = (event) => {
    const newActivityState = !this.props.active;

    this.setState({ active: newActivityState }, () => this.props.onClick && this.props.onClick(this.props.name, newActivityState) );
  }

  render () {
    return (
      <button className={ `btn ${ this.props.active ? 'btn-brand-secondary' : 'btn-brand'} btn-bold btn-portal btn-elevate kt-margin-r-5 kt-margin-b-5` } onClick={ this.toggle }>
        { this.props.name }
      </button>
    )
  }
}


FilterButton.propTypes = {
  active: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func,
};
export default FilterButton
