import React from "react"
import PropTypes from "prop-types"

class CardSelect extends React.Component {
  constructor(props) {
    super(props);

    if (Array.isArray(this.props.options[0])) {
      this.options = this.props.options;
    } else {
      this.options = this.props.options.map((option) => [option, option]);
    }
  }

  render () {
    return (
      <div className="kt-form__control">
        <label>
          { this.props.title || 'Pick the categories:' }
        </label>
        <select
          className="form-control bootstrap-select"
          value={ this.props.values }
          onChange={ this.props.onChange }
          multiple={ true }>
          { this.options.map((option, idx) =>
            <option key={ idx } value={ option[1] }>{ option[0] }</option>
          ) }
        </select>
      </div>
    );
  }
}

CardSelect.propTypes = {
  options: PropTypes.array,
  values: PropTypes.array,
  onChawnge: PropTypes.func
};
export default CardSelect
