import React from "react"
import PropTypes from "prop-types"
import Pagination from "react-js-pagination";
import Card from "./Card";

class CardList extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props.data);
    // this.perPage = this.props.perPage || 12;
    this.cardWrapperClass = this.props.cardWrapperClass || 'col';
    this.isCustom = !!this.props.dataItems;
    this.perPageOptions = props.perPageOptions || [12, 24, 48, 96];

    this.state = {
      activePage: 1,
      perPage: props.perPage || 12,
    };
  }

  handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
    this.setState({ activePage: pageNumber });
  }

  handlePerPageChange = (event) => {
    // console.log(`active per page is ${event.target.value}`);
    this.setState({ perPage: Number(event.target.value) });
  }

  paginate (data) {
    return data.slice((this.state.activePage - 1) * this.state.perPage, this.state.activePage * this.state.perPage)
  }

  data() {
    return this.props.data || this.props.dataItems;
  }

  renderCards () {
    const cards = this.paginate(this.data());

    if (cards.length > 0) {
      if (this.isCustom) {
        return (
          <div className="row">
            { cards.map((cardDataItems, idx) =>
              <div className={ this.cardWrapperClass } key={ idx }>
                <Card {...cardDataItems.cardProps}>
                  { cardDataItems.body }
                </Card>
              </div>
            ) }
          </div>
        )
      } else {
        return (
          <div className="row">
            { cards.map((cardData, idx) =>
              <div className={ this.cardWrapperClass } key={ idx }>
                <Card {...cardData} />
              </div>
            ) }
          </div>
        )
      }
    } else {
      return (
        <div className="row">
          <div className="col">Nothing found</div>
        </div>
      )
    }
  }

  renderPagination () {
    return (
      <nav className="nav-brand">
        <div className="row">
          <div className="col-8">
            <Pagination
              hideDisabled
              itemClass='page-item'
              linkClass='page-link'
              activePage={ this.state.activePage }
              itemsCountPerPage={ this.state.perPage }
              totalItemsCount={ this.data().length }
              pageRangeDisplayed={ 5 }
              onChange={ this.handlePageChange.bind(this) }
            />
          </div>
          <div className="col-4">
            <div className="float-right d-flex flex-row align-items-center">
              <div>On page:</div>
              <div>
                <select
                  className="form-control bootstrap-select"
                  value={ this.state.perPage }
                  onChange={ this.handlePerPageChange }>
                  { this.perPageOptions.map((perPage) =>
                    <option key={ perPage } value={ perPage }>{ perPage }</option>
                  ) }
                </select>
              </div>
            </div>
          </div>
        </div>
      </nav>
    )
  }

  render () {
    // console.log('render CardList');

    return (
      <React.Fragment>
        { this.renderCards() }
        { this.renderPagination() }
      </React.Fragment>
    );
  }
}

CardList.propTypes = {
  data: PropTypes.array,
  cardWrapperClass: PropTypes.string,
  perPage: PropTypes.number
};
export default CardList
