import React from "react"
import PropTypes from "prop-types"
import CardList from "./CardList";
import SearchInput from "./SearchInput";
import CardSelect from "./CardSelect";
import CardTags from "./CardTags";
import Upvote from "./Upvote";

class IndustryChallenges extends React.Component {
  constructor(props) {
    super(props);
    this.cardList = React.createRef();

    this.data = this.props.data;
    this.data = this.data.map(dataItem => {
      if (!dataItem['imageUrl']) {
        dataItem['iconName'] = 'flaticon-presentation'
      }

      return dataItem;
    })

    this.handleClickTag = this.handleClickTag.bind(this);

    this.state = {
      tags: this.parseFilterOptions().tags ? this.parseFilterOptions().tags : [],
      urgency: [],
      size: [],
      problemTypes: [],
      valueChainPositions: [],
      search: '',
      sort: 'createdAt DESC',
    };
  }

  parseFilterOptions() {
    let params = new URLSearchParams(document.location.search)
    let options = {};

    if (params.get("tags")) { options.tags = [params.get("tags")] };

    return options;
  }

  filterItem (dataItem) {
    return (this.state.tags.length === 0 || this.state.tags.filter(x => (dataItem.tags || []).includes(x)).length > 0) &&
            (this.state.urgency.length === 0 || this.state.urgency.includes(dataItem.urgency)) &&
            (this.state.size.length === 0 || this.state.size.includes(dataItem.size)) &&
            (this.state.problemTypes.length === 0 ||
              dataItem.problemType && this.state.problemTypes.includes(dataItem.problemType)) &&
            (this.state.valueChainPositions.length === 0 ||
              dataItem.valueChainPosition && this.state.valueChainPositions.includes(dataItem.valueChainPosition)) &&
            (this.state.search === '' ||
              dataItem.title && dataItem.title.toLowerCase().includes(this.state.search) ||
              dataItem.shortDescription && dataItem.shortDescription.toLowerCase().includes(this.state.search) ||
              dataItem.htmlDescription && dataItem.htmlDescription.toLowerCase().includes(this.state.search));
  }

  filter (data) {
    return data.filter(dataItem => this.filterItem(dataItem));
  }

  sort (data) {
    if (this.state.sort === '') {
      return data;
    }

    const [sortBy, sortOrder] = this.state.sort.split(' ');

    // console.log('FILES#sort', sortBy, sortAsc);

    // if (sortAsc === 'ASC') {
    //   return data.sort((e1, e2) => e1[sortBy] - e2[sortBy] )
    // } else {
    //   return data.sort((e1, e2) => e2[sortBy] - e1[sortBy] )
    // }
    return data.sort(this.sortComparator(sortOrder, sortBy))
  }

  sortComparator (order, sortBy) {
    return function (el1, el2) {
      el1 = el1[sortBy];
      el2 = el2[sortBy];
      if (typeof el1 === 'string' || el1 instanceof String) {
        return (order === 'ASC') && el1.localeCompare(el2) || el2.localeCompare(el1);
      }

      return (order === 'ASC') && (el1 - el2) || (el2 - el1)
    }
  }

  handleClickTag(event) {
    this.setState({ tags: [event.currentTarget.textContent] }, () => { $('.bootstrap-select').selectpicker('refresh'); });
    this.nullifyPagegination();
  }

  handleChangeTags(event) {
    this.setState({ tags: [...event.target.selectedOptions].map(o => o.value) });
    this.nullifyPagegination();
  }

  handleChangeUrgency(event) {
    this.setState({ urgency: [...event.target.selectedOptions].map(o => Number(o.value)) });
    this.nullifyPagegination();
  }

  handleChangeSize(event) {
    this.setState({ size: [...event.target.selectedOptions].map(o => Number(o.value)) });
    this.nullifyPagegination();
  }

  handleChangeProblemTypes() {
    this.setState({ problemTypes: [...event.target.selectedOptions].map(o => o.value) });
    this.nullifyPagegination();
  }

  handleChangeValueChainPositions(event) {
    this.setState({ valueChainPositions: [...event.target.selectedOptions].map(o => o.value) });
    this.nullifyPagegination();
  }

  handleChangeSearch(event) {
    this.setState({ search: event.target.value.toLowerCase() });
    this.nullifyPagegination();
  }

  handleChangeSort(event) {
    this.setState({ sort: event.target.value });
    this.nullifyPagegination();
  }

  nullifyPagegination () {
    this.cardList.current.setState({ activePage: 1 });
  }

  renderDataItem (data) {
    return (
      <React.Fragment>
        <div className="kt-portlet__body kt-portlet__body--fit-bottom">
          { data.iconName &&
            <div className="text-center">
              <i className={`${data.iconName} card-image`} />
            </div> }
          { data.imageUrl &&
            <div className="card-image text-center d-flex">
              <img className="align-self-center" src={ data.imageUrl } />
            </div> }
          { data.title && (
              data.link &&
                <a className="kt-font-dark" href={data.link}>
                  <h3 className="kt-margin-b-10 card-title wrap-3">
                    {data.title}
                  </h3>
                </a> ||
                <h3 className="kt-font-dark kt-margin-b-10 card-title wrap-3">{data.title}</h3>) }
          <div className="row">
            { data.urgency && <div className="col-6">Priority: {data.urgency}</div> }
            { data.valueChainPosition && <div className="col-6 text-capitalize text-right">{data.valueChainPosition}</div> }
            { data.size && <div className="col-6">Industry Width: {data.size}</div> }
            { data.problemType && <div className="col-6 text-capitalize text-right">{data.problemType}</div> }
          </div>
          { data.shortDescription && <div className="kt-font-dark card-description kt-margin-t-10">{ data.shortDescription } </div> }

          { data.tags && <CardTags tags={ data.tags } onClick={ this.handleClickTag } /> }
        </div>
        <div className="kt-portlet__foot kt-portlet__foot--no-border">
          <div className="row">
            <div className="col-3">
              <Upvote { ...data.upvotes } upvotableId={ data.id } upvotableType='IndustryChallenge' />
            </div>
            <div className="col-9 d-flex">
              { data.link && <a className="btn btn-brand btn-bold btn-portal btn-elevate align-self-center" href={data.link}>{data.linkTitle || 'Read more'}</a> }
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  render () {
    return (
      <React.Fragment>
        {
          !this.props.hideFilters &&
            <div className="row kt-margin-b-20-desktop">
              <div className="col-md-3 kt-margin-b-20-tablet-and-mobile">
                <SearchInput onChange={ this.handleChangeSearch.bind(this) } />
              </div>

              <div className="col-md-3 kt-margin-b-20-tablet-and-mobile">
                <CardSelect
                  values={ this.state.tags }
                  onChange={ this.handleChangeTags.bind(this) }
                  options={ this.props.tags }
                  title={ this.props.tagsTitle } />
              </div>

              <div className="col-md-2 kt-margin-b-20-tablet-and-mobile">
                <CardSelect
                  values={ this.state.problemTypes }
                  onChange={ this.handleChangeProblemTypes.bind(this) }
                  options={ this.props.problemTypes }
                  title={ this.props.problemTypeTitle } />
              </div>

              <div className="col-md-2 kt-margin-b-20-tablet-and-mobile">
                <CardSelect
                  values={ this.state.valueChainPositions }
                  onChange={ this.handleChangeValueChainPositions.bind(this) }
                  options={ this.props.valueChainPositions }
                  title={ this.props.valueChainPositionTitle } />
              </div>

              <div className="col-md-2">
                <div className="kt-form__control">
                  <label>
                    Sort by:
                  </label>
                  <select
                    className="form-control bootstrap-select"
                    value={ this.state.sort }
                    onChange={ this.handleChangeSort.bind(this) }>
                    <option value="urgency DESC">Priority (hi-lo)</option>
                    <option value="urgency ASC">Priority (lo-hi)</option>
                    <option value="size DESC">Market size (hi-lo)</option>
                    <option value="size ASC">Market size (lo-hi)</option>
                    <option value="title ASC">Alphabetical (a-z)</option>
                    <option value="title DESC">Alphabetical (z-a)</option>
                    <option value="createdAt DESC">Added (newest first)</option>
                    <option value="createdAt ASC">Added (oldest first)</option>
                  </select>
                </div>
              </div>
            </div>
        }

        <CardList
          ref={ this.cardList }
          dataItems={ this.sort(this.filter(this.data)).map(dataItem => { return { body: this.renderDataItem(dataItem) } }) }
          perPage={ 12 }
          cardWrapperClass={ 'col-lg-3 col-md-4' }
        />
      </React.Fragment>
    );
  }
}

IndustryChallenges.propTypes = {
  data: PropTypes.array,
  cardWrapperClass: PropTypes.string,
  hideFilters: PropTypes.bool,
};
export default IndustryChallenges

// { data.tags && <div>{ data.tags.map ((tag, idx) => <div className="d-inline-flex kt-link kt-margin-r-5" key={ idx }>#{ tag }</div>) }</div> }
