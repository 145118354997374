import React from "react"
import PropTypes from "prop-types"
import SearchInput from "./SearchInput";
import CardList from "./CardList";
import CardSelect from "./CardSelect";

class Companies extends React.Component {
  constructor(props) {
    super(props);
    this.cardList = React.createRef();

    this.handleClickTag = this.handleClickTag.bind(this);

    this.data = this.props.data.map(dataItem => {
      if (!dataItem['imageUrl']) {
        dataItem['iconName'] = 'la la-building'
      }

      dataItem['handleClickTag'] = this.handleClickTag;

      return dataItem;
    })

    this.state = {
      categories: [],
      tags: this.parseFilterOptions().tags ? this.parseFilterOptions().tags : [],
      search: '',
      sort: 'published_at DESC'
    };
  }

  parseFilterOptions() {
    let params = new URLSearchParams(document.location.search)
    let options = {};

    if (params.get("tags")) { options.tags = [params.get("tags")] };

    return options;
  }

  filterItem (dataItem) {
    return (this.state.categories.length === 0 || this.state.categories.filter(x => (dataItem.industryCategories || []).includes(x)).length > 0) &&
            (this.state.tags.length === 0 || this.state.tags.filter(x => (dataItem.tags || []).includes(x)).length > 0) &&
            (this.state.search === '' ||
              dataItem.title && dataItem.title.toLowerCase().includes(this.state.search) ||
              dataItem.description && dataItem.description.toLowerCase().includes(this.state.search));
  }

  filter (data) {
    return data.filter(companyData => this.filterItem(companyData));
  }

  sort (data) {
    if (this.state.sort === '') {
      return data;
    }

    const [sortBy, sortAsc] = this.state.sort.split(' ');

    // console.log('FILES#sort', sortBy, sortAsc);

    if (sortAsc === 'ASC') {
      return data.sort((e1, e2) => e1[sortBy] - e2[sortBy] )
    } else {
      return data.sort((e1, e2) => e2[sortBy] - e1[sortBy] )
    }
  }

  handleClickTag(event) {
    this.setState({ tags: [event.currentTarget.textContent] }, () => { $('.bootstrap-select').selectpicker('refresh'); });
    this.nullifyPagegination();
  }

  handleChangeCategories(event) {
    this.setState({ categories: [...event.target.selectedOptions].map(o => o.value) });
    this.nullifyPagegination();
  }

  handleChangeTags(event) {
    this.setState({ tags: [...event.target.selectedOptions].map(o => o.value) });
    this.nullifyPagegination();
  }

  handleChangeSearch(event) {
    this.setState({ search: event.target.value.toLowerCase() });
    this.nullifyPagegination();
  }

  handleChangeSort(event) {
    this.setState({ sort: event.target.value });
    this.nullifyPagegination();
  }

  nullifyPagegination () {
    this.cardList.current.setState({ activePage: 1 });
  }

  render () {
    return (
      <React.Fragment>
        <div className="row kt-margin-b-20">
          <div className="col-md-3 kt-margin-b-20-tablet-and-mobile">
            <SearchInput onChange={ this.handleChangeSearch.bind(this) } />
          </div>

          <div className="col-md-3 kt-margin-b-20-tablet-and-mobile">
            <CardSelect
              values={ this.state.categories }
              onChange={ this.handleChangeCategories.bind(this) }
              options={ this.props.industryCategories } />
          </div>

          <div className="col-md-3 kt-margin-b-20-tablet-and-mobile">
            <CardSelect
              values={ this.state.tags }
              onChange={ this.handleChangeTags.bind(this) }
              options={ this.props.tags }
              title={ this.props.tagsTitle } />
          </div>

          <div className="col-md-3">
            <div className="kt-form__control">
              <label>
                Sort by:
              </label>
              <select
                className="form-control bootstrap-select"
                value={ this.state.sort }
                onChange={ this.handleChangeSort.bind(this) }>
                <option value="created_at DESC">Added (newest first)</option>
                <option value="created_at ASC">Added (oldest first)</option>
                <option value="updated_at DESC">Updated (newest first)</option>
                <option value="updated_at ASC">Updated (oldest first)</option>
              </select>
            </div>
          </div>
        </div>

        <CardList
          ref={ this.cardList }
          data={ this.filter(this.sort(this.data)) }
          perPage={ 12 }
          cardWrapperClass={ 'col-lg-3 col-md-4' }
        />
      </React.Fragment>
    );
  }
}

Companies.propTypes = {
  data: PropTypes.array,
  cardWrapperClass: PropTypes.string
};
export default Companies
