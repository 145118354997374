// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
// require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import('./bootstrap_custom.js')
import('bootstrap-select/js/bootstrap-select')
// import('block-ui/jquery.blockUI')

import PerfectScrollbar from 'perfect-scrollbar';
window.PerfectScrollbar = PerfectScrollbar;

// Initialise theme scripts
window.KTUtil = require('./global/components/base/util');
window.KTHeader = require('./global/components/base/header');
window.KTMenu = require('./global/components/base/menu');
window.KTOffcanvas = require('./global/components/base/offcanvas');
window.KTScrolltop = require('./global/components/base/scrolltop');
window.KTToggle = require('./global/components/base/toggle');
// window.KTPortlet = require('./global/components/base/portlet');
window.Sticky = require('sticky-js/dist/sticky.min');
window.KTAppOptions = {
  "colors": {
    "state": {
      "brand": "#3d94fb",
      "light": "#ffffff",
      "dark": "#282a3c",
      "primary": "#5867dd",
      "success": "#34bfa3",
      "info": "#3d94fb",
      "warning": "#ffb822",
      "danger": "#fd27eb"
    },
    "base": {
      "label": ["#c5cbe3", "#a1a8c3", "#3d4465", "#3e4466"],
      "shape": ["#f0f3ff", "#d9dffa", "#afb4d4", "#646c9a"]
    }
  }
};

import('select2');
import('./portal.js');

import 'bootstrap/js/dist/tooltip';
window.KTApp = require('./global/components/base/app');

import('./pages/custom/login/login-general.js');
import('./global/layout/layout.js');
import('./global/layout/quick-search.js');
import('./global/components/base/datatable/core.datatable.js');
import('./pages/crud/metronic-datatable/base/html-table.js');
// import('./pages/crud/metronic-datatable/base/data-json.js');

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

import('cocoon-js');
import * as Routes from '../routes';
window.Routes = Routes;
