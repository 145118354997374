import React from "react"
import PropTypes from "prop-types"
import Modal from 'react-bootstrap/Modal';

class ModalButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    }
  }

  handleOpen = () => {
    this.setState({ isOpen: true });

    return false;
  }

  handleClose = () => {
    this.setState({ isOpen: false });
  }

  render () {
    return (
      <div className="modal-button">
        <button className={ this.props.buttonClass } onClick={ this.handleOpen }>{ this.props.buttonLabel }</button>

        <Modal show={ this.state.isOpen } onHide={ this.handleClose } contentClassName="bg-transparent border-none" dialogClassName="modal-xl modal-dialog modal-dialog-centered">
          <Modal.Header closeButton className="border-none">
            { this.props.modalHeader && <Modal.Title>{ this.props.modalHeader }</Modal.Title> }
          </Modal.Header>
          <Modal.Body>{ this.props.modalContent }</Modal.Body>
          { this.props.modalFooter && <Modal.Footer>{ this.props.modalFooter }</Modal.Footer> }
        </Modal>
      </div>
    );
  }
}

ModalButton.propTypes = {
  options: PropTypes.array,
  values: PropTypes.array,
  modalContent: PropTypes.object,
};
export default ModalButton
