import React from "react"
import PropTypes from "prop-types"

import * as Routes from '../routes';

class InternalMessageShare extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      url: '',
    };
  }

  getText = () => {
    navigator.clipboard.writeText(window.location);
  }
    // alert(Routes.startup_files_path);

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    let name = target.name;

    this.setState({
      [name]: value
    });
  }

  share = () => {
    fetch(Routes.company_recommendations_path(), {
      method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          authenticity_token: document.querySelector('meta[name=csrf-token]').content,
          company_recommendation: {
            text: this.state.text,
            url: this.state.url,
          }
        }),
    }).then(res => res.json())
    .then(res => window.location.reload())
    .error(error => console.log(error));
  }

  render () {
    return (
      <div className="row kt-margin-b-20-desktop">
        {/*<div className="col-md-3 kt-margin-b-20-tablet-and-mobile">
          <a className="btn btn-brand btn-bold btn-portal btn-elevate text-white" onClick={ this.getText }>Copy URL</a>
        </div>*/}

        <div className="col-md-3 kt-margin-b-20-tablet-and-mobile">
          <input type="text" className="form-control" placeholder="Message text goes here" name="text" onChange={ this.handleInputChange } value={ this.state.text } autoComplete="off"/>
        </div>

        <div className="col-md-3 kt-margin-b-20-tablet-and-mobile">
          <input type="text" className="form-control" placeholder="Put URL here" name="url" onChange={ this.handleInputChange } value={ this.state.url} autoComplete="off"/>
        </div>

        <div className="col-md-3 kt-margin-b-20-tablet-and-mobile">
          <a className="btn btn-brand btn-bold btn-portal btn-elevate text-white kt-margin-r-10" onClick={ this.getText }>Copy URL</a>
          <a className="btn btn-brand btn-bold btn-portal btn-elevate text-white" onClick={ this.share }>Share</a>
        </div>
      </div>
    );
  }
}

// InternalMessageShare.propTypes = {
//   copyText: PropTypes.func,
// };
export default InternalMessageShare
