import React from "react"
import PropTypes from "prop-types"

class CardTags extends React.Component {
  constructor(props) {
    super(props);
    this.tagClass = `kt-badge kt-badge--inline kt-badge--bold kt-badge--pill kt-margin-r-5 kt-margin-b-5 cursor-pointer ${props.tagClass || 'kt-badge--portal-orange'}`;
    this.tagsWrapperClass = props.wrapperClass || 'kt-margin-t-10';
    this.noWrapper = props.noWrapper || false;
  }

  render () {
    const data = this.props.tags.map ((tag, idx) => <span className={ this.tagClass } key={ idx } onClick={ this.props.onClick }>{ tag }</span>);

    if (this.props.noWrapper) {
      return (
        <React.Fragment>
          { data }
        </React.Fragment>
      )
    } else {
      return (
        <div className={ this.tagsWrapperClass }>{ data }</div>
      );
    }
  }
}

CardTags.propTypes = {
  tags: PropTypes.array,
};
export default CardTags
