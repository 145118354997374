import React from "react"
import PropTypes from "prop-types"
import CardList from "./CardList";
import SearchInput from "./SearchInput";
import CardSelect from "./CardSelect";
import CardTags from "./CardTags";

class Startups extends React.Component {
  constructor(props) {
    super(props);
    this.cardList = React.createRef();

    this.handleClickTag = this.handleClickTag.bind(this);
    this.handleClickStageTag = this.handleClickStageTag.bind(this);
    this.handleClickIndustryCategoryTag = this.handleClickIndustryCategoryTag.bind(this);
    this.handleClickTechReadinessLevelTag = this.handleClickTechReadinessLevelTag.bind(this);

    this.data = this.props.data;
    this.data = this.data.map(dataItem => {
      if (!dataItem['imageUrl']) {
        dataItem['iconName'] = 'la la-rocket'
      }

      return dataItem;
    })

    this.state = {
      tags: this.parseFilterOptions().tags ? this.parseFilterOptions().tags : [],
      remarks: [],
      industryCategories: [],
      stages: [],
      techReadinessLevels: [],
      countries: [],
      search: '',
      sort: 'createdAt DESC',
    };
  }

  parseFilterOptions() {
    let params = new URLSearchParams(document.location.search)
    let options = {};

    if (params.get("tags")) { options.tags = [params.get("tags")] };

    return options;
  }

  filterItem (dataItem) {
    return (this.state.tags.length === 0 || this.state.tags.filter(x => (dataItem.tags || []).includes(x)).length > 0) &&
            (this.state.remarks.length === 0 || this.state.remarks.filter(x => (dataItem.admin_remarks || []).includes(x)).length > 0) &&
            (this.state.industryCategories.length === 0 || this.state.industryCategories.includes(dataItem.industryCategory)) &&
            (this.state.stages.length === 0 || this.state.stages.includes(dataItem.stage)) &&
            (this.state.techReadinessLevels.length === 0 ||
              dataItem.techReadinessLevel && this.state.techReadinessLevels.includes(dataItem.techReadinessLevel)) &&
            (this.state.countries.length === 0 ||
              dataItem.country && this.state.countries.includes(dataItem.country)) &&
            (this.state.search === '' ||
              dataItem.name && dataItem.name.toLowerCase().includes(this.state.search) ||
              dataItem.tagline && dataItem.tagline.toLowerCase().includes(this.state.search));
  }

  filter (data) {
    return data.filter(dataItem => this.filterItem(dataItem));
  }

  sort (data) {
    if (this.state.sort === '') {
      return data;
    }

    const [sortBy, sortOrder] = this.state.sort.split(' ');

    // console.log('FILES#sort', sortBy, sortAsc);

    // if (sortAsc === 'ASC') {
    //   return data.sort((e1, e2) => e1[sortBy] - e2[sortBy] )
    // } else {
    //   return data.sort((e1, e2) => e2[sortBy] - e1[sortBy] )
    // }
    return data.sort(this.sortComparator(sortOrder, sortBy))
  }

  sortComparator (order, sortBy) {
    return function (el1, el2) {
      el1 = el1[sortBy];
      el2 = el2[sortBy];
      if (typeof el1 === 'string' || el1 instanceof String) {
        return (order === 'ASC') && el1.localeCompare(el2) || el2.localeCompare(el1);
      }

      return (order === 'ASC') && (el1 - el2) || (el2 - el1)
    }
  }

  handleClickTag(event) {
    this.setState({ tags: [event.currentTarget.textContent] }, () => { $('.bootstrap-select').selectpicker('refresh'); });
    this.nullifyPagegination();
  }

  handleClickStageTag(event) {
    this.setState({ stages: [event.currentTarget.textContent] }, () => { $('.bootstrap-select').selectpicker('refresh'); });
    this.nullifyPagegination();
  }

  handleClickIndustryCategoryTag(event) {
    this.setState({ industryCategories: [event.currentTarget.textContent] }, () => { $('.bootstrap-select').selectpicker('refresh'); });
    this.nullifyPagegination();
  }

  handleClickTechReadinessLevelTag(event) {
    this.setState({ techReadinessLevels: [Number(event.currentTarget.textContent.match(/\d+/)[0])] }, () => { $('.bootstrap-select').selectpicker('refresh'); });
    this.nullifyPagegination();
  }

  handleChangeTags(event) {
    this.setState({ tags: [...event.target.selectedOptions].map(o => o.value) });
    this.nullifyPagegination();
  }

  handleChangeIndustryCategories(event) {
    this.setState({ industryCategories: [...event.target.selectedOptions].map(o => o.value) });
    this.nullifyPagegination();
  }

  handleChangeStages(event) {
    this.setState({ stages: [...event.target.selectedOptions].map(o => o.value) });
    this.nullifyPagegination();
  }

  handleChangeTechReadinessLevels() {
    this.setState({ techReadinessLevels: [...event.target.selectedOptions].map(o => Number(o.value)) });
    this.nullifyPagegination();
  }

  handleChangeCountries(event) {
    this.setState({ countries: [...event.target.selectedOptions].map(o => o.value) });
    this.nullifyPagegination();
  }

  handleChangeRemarks(event) {
    this.setState({ remarks: [...event.target.selectedOptions].map(o => o.value) });
    this.nullifyPagegination();
  }

  handleChangeSearch(event) {
    this.setState({ search: event.target.value.toLowerCase() });
    this.nullifyPagegination();
  }

  handleChangeSort(event) {
    this.setState({ sort: event.target.value });
    this.nullifyPagegination();
  }

  nullifyPagegination () {
    this.cardList.current.setState({ activePage: 1 }, () => {
      $('[data-toggle="kt-tooltip"]').each(function() {
        KTApp.initTooltip($(this));
      });
    });
  }

  renderRemarks(data) {
    return (
      <React.Fragment>
        { data.adminRemarkIcons.map ((remark, idx) => <div className="d-inline-flex kt-margin-l-5" key={ idx }><img className="startup-name-remark" src={ remark.imagePath } data-toggle='kt-tooltip' data-html={true} data-original-title={ remark.description } /></div>) }
      </React.Fragment>
    )
  }

  renderDataItem(data) {
    return (
      <React.Fragment>
        <div className="kt-portlet__body kt-portlet__body--fit-bottom">
          { data.iconName &&
            <div className="text-center">
              <i className={`${data.iconName} card-image`} />
            </div> }
          { data.imageUrl &&
            <div className="card-image text-center d-flex">
              <img className="align-self-center" src={ data.imageUrl } />
            </div> }
          { data.name && (
              data.link &&
                <a className="kt-font-dark" href={data.link}>
                  <h3 className="kt-margin-b-20 card-title d-inline">{ data.name }</h3>
                  { this.renderRemarks(data) }
                </a> ||
                <React.Fragment>
                  <h3 className="kt-font-dark kt-margin-b-20 card-title d-inline">{ data.name }</h3>
                  { this.renderRemarks(data) }
                </React.Fragment>) }
          { data.tagline && <div className="kt-font-brand card-description kt-margin-b-10">{ data.tagline } </div> }
          <div>
            { data.stage && <CardTags tags={ [data.stage] } onClick={ this.handleClickStageTag } wrapperClass="d-inline-flex" tagClass="kt-badge--portal-green" /> }
            { data.industryCategory && <CardTags tags={ [data.industryCategory] } onClick={ this.handleClickIndustryCategoryTag } wrapperClass="d-inline-flex text-capitalize" tagClass="kt-badge--brand" /> }
            { data.techReadinessLevel && <CardTags tags={ [`TRL ${data.techReadinessLevel}`] } onClick={ this.handleClickTechReadinessLevelTag } wrapperClass="d-inline-flex text-capitalize" tagClass="kt-badge--portal-orange1" /> }
          </div>
          { data.tags && <CardTags tags={ data.tags } onClick={ this.handleClickTag } /> }
        </div>
        <div className="kt-portlet__foot kt-portlet__foot--no-border">
          { data.link && <a className="btn btn-brand btn-bold btn-portal btn-elevate" href={data.link}>{data.linkTitle || 'Read more'}</a> }
        </div>
      </React.Fragment>
    )
  }

  // Country filter
  // <div className="col-md-3 kt-margin-b-20-tablet-and-mobile">
  //   <CardSelect
  //     values={ this.state.countries }
  //     onChange={ this.handleChangeCountries.bind(this) }
  //     options={ this.props.countries }
  //     title={ this.props.countryTitle } />
  // </div>
  render () {
    return (
      <React.Fragment>
          {
            !this.props.hideFilters &&
              <React.Fragment>
                <div className="row kt-margin-b-20-desktop">
                  <div className="col-md-3 kt-margin-b-20-tablet-and-mobile">
                    <SearchInput onChange={ this.handleChangeSearch.bind(this) } />
                  </div>

                  <div className="col-md-3 kt-margin-b-20-tablet-and-mobile">
                    <CardSelect
                      values={ this.state.remarks }
                      onChange={ this.handleChangeRemarks.bind(this) }
                      options={ this.props.remarks }
                      title={ this.props.remarkTitle } />
                  </div>

                  <div className="col-md-3 kt-margin-b-20-tablet-and-mobile">
                    <CardSelect
                      values={ this.state.industryCategories }
                      onChange={ this.handleChangeIndustryCategories.bind(this) }
                      options={ this.props.industryCategories }
                      title={ this.props.industryCategoriesTitle } />
                  </div>

                  <div className="col-md-3 kt-margin-b-20-tablet-and-mobile">
                    <CardSelect
                      values={ this.state.tags }
                      onChange={ this.handleChangeTags.bind(this) }
                      options={ this.props.tags }
                      title={ this.props.tagsTitle } />
                  </div>
                </div>

                <div className="row kt-margin-b-20-desktop">
                  <div className="col-md-3 kt-margin-b-20-tablet-and-mobile">
                    <CardSelect
                      values={ this.state.stages }
                      onChange={ this.handleChangeStages.bind(this) }
                      options={ this.props.stages }
                      title={ this.props.stageTitle } />
                  </div>

                  <div className="col-md-3 kt-margin-b-20-tablet-and-mobile">
                    <CardSelect
                      values={ this.state.techReadinessLevels }
                      onChange={ this.handleChangeTechReadinessLevels.bind(this) }
                      options={ this.props.techReadinessLevels }
                      title={ this.props.techReadinessLevelsTitle } />
                  </div>

                  <div className="col-md-3">
                    <div className="kt-form__control">
                      <label>
                        Sort by:
                      </label>
                      <select
                        className="form-control bootstrap-select"
                        value={ this.state.sort }
                        onChange={ this.handleChangeSort.bind(this) }>
                        <option value="name ASC">Name (alphabetical)</option>
                        <option value="name DESC">Name (alphabetical reverse)</option>
                        <option value="updatedAt DESC">Last updated (newest first)</option>
                        <option value="updatedAt ASC">Last updated (oldest first)</option>
                        <option value="createdAt DESC">Created (newest first)</option>
                        <option value="createdAt ASC">Created (oldest first)</option>
                      </select>
                    </div>
                  </div>
                </div>
              </React.Fragment>
          }

        <CardList
          ref={ this.cardList }
          dataItems={ this.sort(this.filter(this.data)).map(dataItem => { return { body: this.renderDataItem(dataItem) } }) }
          perPage={ 12 }
          cardWrapperClass={ 'col-lg-3 col-md-4' }
        />
      </React.Fragment>
    );
  }
}

Startups.propTypes = {
  data: PropTypes.array,
  cardWrapperClass: PropTypes.string,
  hideFilters: PropTypes.bool,
};
export default Startups
