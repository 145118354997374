import React from "react"
import PropTypes from "prop-types"
import Pagination from "react-js-pagination";

import CardTags from "./CardTags";
import ContentRecommendationRibbon from "./ContentRecommendationRibbon";


class Card extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hovered: false,
      contentRecommendations: props.contentRecommendations,
    }
  }

  renderHeaderActions () {
    return (
      <div className="kt-portlet__head kt-portlet__head--noborder">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title" />
        </div>
        <div className="kt-portlet__head-toolbar">
          <div className="dropdown dropdown-inline">
            <button className="btn btn-clean btn-sm btn-icon btn-icon-lg" aria-expanded="false" aria-haspopup="true" data-toggle="dropdown" type="button">
              <i className="la la-ellipsis-h" />
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <ul className="kt-nav">
                {
                  this.props.actions.map((action, idx) => {
                    return (
                      <li className="kt-nav__item" key={ idx }>
                        <a className="kt-nav__link" href={ action.path } data-method={ action.method }>
                          <i className={`la la-${action.iconName} kt-margin-r-5 ${action.method === 'delete' && 'text-danger' || 'kt-font-brand'}`} />
                          <span className={ `kt-nav__link-text ${action.method === 'delete' && 'text-danger' || ''}` }>{ action.title }</span>
                        </a>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderTitle () {
    if (this.props.title) {
      if (this.props.link) {
        return (
          <a className="kt-font-dark" href={this.props.link} target="_blank">
            <h3 className="kt-margin-b-10 card-title">
              {this.props.title}
            </h3>
          </a>)
      } else {
        return (<h3 className="kt-font-dark kt-margin-b-10 card-title">{this.props.title}</h3>)
      }
    }
  }

  renderAuthorLink (name, url, key=0) {
    return (
      <a className="kt-link kt-margin-r-5" href={url} target="_blank" key={ key }>
        <div className="kt-font-brand">{name}{ this.props.authors && (key != (this.props.authors.length - 1)) ? ',' : '' }</div>
      </a>
    )
  }

  renderAuthor () {
    if (this.props.author) {
      if (this.props.authorLink) {
        return this.renderAuthorLink(this.props.author, this.props.authorLink)
      } else {
        return (<div className="kt-font-brand">{this.props.author}</div>)
      }
    } else if (this.props.authors) {
      return (
        <div>
          {
            this.props.authors.map((data, idx) => this.renderAuthorLink(data.name, data.url, idx))
          }
        </div>
      )
    }
  }

  ribbonCallback = (type, id) => {
    let contentRecommendations = this.state.contentRecommendations;
    contentRecommendations[type] = id;

    this.setState({ contentRecommendations: contentRecommendations });
  }

  renderRibbons () {
    let contentRecommendations = this.state.contentRecommendations;
    // if (this.props.contentRecommendations?.length > 0) {
    // if (this.props.contentRecommendations && Object.values(this.props.contentRecommendations).some(v => v == true)) {
    if (contentRecommendations) {
      const recommendable = this.props.contentRecommendable && Object.entries(this.props.contentRecommendable).filter(v => v[1]).map(v => v[0]);
      let idx = -1;

      return (
        <React.Fragment>
          {
            Object.entries(contentRecommendations).filter((val) => val[1]).map((val) => {
              idx += 1;
              const type = val[0], itemId = val[1];

              return <ContentRecommendationRibbon id={ itemId } type={ type } idx={ idx } key={ itemId }
                editable={ this.props.contentRecommendable[type] } callback={ this.ribbonCallback } itemData={ this.props.contentRecommendableItem }
                popupDescription={ this.props.contentRecommendablePopups[type] }
              />;
            })
          }

          {
            recommendable?.length > 0 &&
              this.state.hovered &&
              Object.entries(contentRecommendations).filter((val) => !val[1] && recommendable.includes(val[0])).map((val) => {
              idx += 1;
              const type = val[0], itemId = val[1];

              return <ContentRecommendationRibbon id={ val[1] } type={ val[0] } idx={ idx } key = { val[0] }
                editable={ this.props.contentRecommendable[val[0]] } callback={ this.ribbonCallback } itemData={ this.props.contentRecommendableItem }
                popupDescription={ this.props.contentRecommendablePopups[type] }
              />;
            })
          }

        </React.Fragment>
      )
    }
  }

  render () {
    const cardClass = "kt-portlet kt-portlet--head--noborder kt-portlet--height-fluid card-button kt-ribbon kt-ribbon--brand kt-ribbon--ver kt-ribbon--bottom kt-ribbon--shadow kt-ribbon--round";

    if (this.props.children) {
      return (
        <div className={ cardClass } onMouseEnter={ () => this.setState({hovered: true}) } onMouseLeave={ () => this.setState({hovered: false}) }>
          { this.state.contentRecommendations && this.renderRibbons() }
          { this.props.actions && this.renderHeaderActions() }
          { this.props.children }
        </div>
      );
    } else {
      return (
        <div className={ cardClass } onMouseEnter={ () => this.setState({hovered: true}) } onMouseLeave={ () => this.setState({hovered: false}) }>
          { this.state.contentRecommendations && this.renderRibbons() }
          { this.props.actions && this.renderHeaderActions() }
          <div className="kt-portlet__body kt-portlet__body--fit-bottom">
            { this.props.iconName &&
              <div className="text-center">
                <i className={`${this.props.iconName} card-image`} />
              </div> }
            { this.props.imageUrl &&
              <div className="card-image text-center d-flex">
                <img className="align-self-center" src={ this.props.imageUrl } />
              </div> }
            { this.renderTitle() }
            { this.renderAuthor() }
            { this.props.description && <div className="kt-font-dark card-description">{this.props.description}</div> }
            { this.props.extraDescription && <div className="kt-font-dark card-description">{this.props.extraDescription}</div> }
            { this.props.tags && <CardTags tags={ this.props.tags } onClick={ this.props.handleClickTag } /> }
            { this.props.series && <CardTags tags={ this.props.series } onClick={ this.props.handleClickSeries } noWrapper={ true } /> }
          </div>
          <div className="kt-portlet__foot kt-portlet__foot--no-border">
            { this.props.link && <a className="btn btn-brand btn-bold btn-portal btn-elevate" href={this.props.link} target="_blank">{this.props.linkTitle || 'Read more'}</a> }
          </div>
        </div>
      );
    }
  }
}
          // { this.props.imageUrl && <img className="card-image" src={ this.props.imageUrl } /> }

Card.propTypes = {
  iconName: PropTypes.string,
  imageUrl: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  linkTitle: PropTypes.string,
  contentRecommendations: PropTypes.object,
  // actions: PropTypes.array
};
export default Card
