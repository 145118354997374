import React from "react"
import PropTypes from "prop-types"

const iconClasses = {
  admin: {
    // true: 'fa fa-heart',
    // false: 'far fa-heart',
    true: 'fa fa-star',
    false: 'far fa-star',
  },
  profile: {
    true: 'fa-solid fa-crown',
    false: 'la fa-crown',
  },
}
const margins = ['20px', '70px', '120px', '170px'];

class ContentRecommendationRibbon extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id,
      active: !!props.id,
      hovered: false,
    }
  }

  onClick = (event) => {
    if (this.props.editable) {
      this.setState({ active: !this.state.active });

      // Destroy tooltip, which otherwise moves to the weird location on the page
      $(event.currentTarget).tooltip('dispose');

      if (this.state.active) {
        // Delete content recommendation
        fetch(Routes.content_recommendation_path(this.state.id), {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            authenticity_token: document.querySelector('meta[name=csrf-token]').content,
          }),
        }).then(res => res.json())
        .then(res => {
          if (res.status == 'ok') {
            this.props.callback(this.props.type, null)
          } else {
            throw new Error('Something went wrong');
          }
        })
        .catch(error => {
          console.error(error);
          this.setState({ active: !this.state.active });
          // alert(error.message);
          alert('Something went wrong :(');
        });
      } else {
        // Create content recommendation
        fetch(Routes.content_recommendations_path(), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            authenticity_token: document.querySelector('meta[name=csrf-token]').content,
            content_recommendation: {
              ...this.props.itemData,
              scope: this.props.type,
            }
          }),
        }).then(res => res.json())
        .then(res => {
          if (res.status == 'ok') {
            if (this.props.type == 'profile') {
              $('#new_company_post_recommendation_modal').modal('show');
              $('#company_recommendation_url_post').val(res.url);
            }

            this.setState({ id: res.id });
            this.props.callback(this.props.type, res.id)
          } else {
            throw new Error('Something went wrong');
          }
        })
        .catch(error => {
          console.error(error);
          this.setState({ active: !this.state.active });
          // alert(error.message);
          alert('Something went wrong :(');
        });
      }
    }
  }

  render () {
    return (
      <div
        className="kt-ribbon__target"
        style={{ top: '-2px', left: margins[this.props.idx] }}
        onMouseEnter={ () => this.setState({ hovered: true }) }
        onMouseLeave={ () => this.setState({ hovered: false }) }
        onClick={ this.onClick }
        // Render popup
        data-toggle='kt-tooltip' data-html={ true } data-original-title={ this.props.popupDescription }
      >
        <i className={ iconClasses[this.props.type][this.state.active || this.state.hovered] }></i>
      </div>
    )
  }
}

ContentRecommendationRibbon.propTypes = {
  id: PropTypes.number,
  type: PropTypes.string,
  idx: PropTypes.number,
  editable: PropTypes.bool,
  itemData: PropTypes.object,
  callback: PropTypes.func,
  popupDescription: PropTypes.string,
};
export default ContentRecommendationRibbon
